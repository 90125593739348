@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&family=Poppins:wght@300&display=swap");

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif !important  ;
}
.carousel-item {
  height: 100vh;
  overflow: hidden;
  background-size: cover;
}
.top-nav-collapse {
  background-color: #0e153a !important;
}
.navpad {
  margin-right: 50px;
}
.carousel-caption h3 {
  padding-top: 0rem;
  text-align: left;
  font-size: 4rem;
  width: 50vw;
  margin-top: 10%;
  font-weight: 600;
}
.carousel-caption {
  top: 25%;
  text-align: start !important;
}
.carousel-caption > span {
  font-size: 26px;
  font-weight: 400;
  text-align: start;
}

.navbar {
  box-shadow: none !important;
}

nav ul li a {
  display: block;
  /* padding: 10px; */
  text-decoration: none;
  color: #aaa;
  font-weight: 800;
  text-transform: uppercase;
  margin-left: 10px;
}

.navabr-logo {
  margin-left: 200px !important;
  cursor: pointer;
  width: 20px;
}

.item {
  margin: 20px;
  height: 100px !important ;
  display: flex;
}

.brands {
  width: 20px !important;
}

.foot {
  width: 100%;
}
/* 
h1,h2,h3,h4,h5{
    
    font-family:'Montserrat', sans-serif !important;
} */
body {
  margin: 0;
}
/* p, h2{
  
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
} */
/* p{
    color: #E66255;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
} */
.wave-container {
  width: 100vw;
  /* position: relative; */
  color: #fff;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-attachment: fixed; */
  background-size: cover;
  /* height:80vh !important; */
  overflow: hidden;
}
.top-carousel .wave-container {
  height: 80vh !important;
}
.gradient {
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), rgba(255, 0, 0, 1));
  width: 30rem;

  height: 10rem;
}
.container {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}
.container {
  max-width: 100vw !important;
}
main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  margin: 0 !important;
}
.top-h1,
.top-p {
  padding-top: 0rem;
  text-align: left;
  width: 48vw;
  padding-left: 3rem !important;
  height: 76px;
}
.top-p {
  font-weight: 300 !important;
}
.top-h1 {
  margin-top: 6rem !important;
  font-weight: 900 !important;
}
.top-btn {
  position: absolute;
  left: 3rem;
}
.container-2,
.container-4 {
  text-align: center;
  margin: 3rem 0 !important;
}
.flex {
  display: flex;
  margin-top: 3rem !important;
}
.owl-brand {
  margin-top: 3rem !important;
}
.brands {
  height: 85px;
  width: 50px !important;
  margin: auto !important;
}
.blue {
  background-color: #add8e6;
  color: blue;
  width: max-content;
}

.container-3 {
  margin-top: 35vh !important;
}
.left {
  padding-top: 20vh !important;
}
.left_para {
  width: 80% !important;
}
.services_p {
  padding: 0 10vw !important;
  margin-bottom: 5rem !important;
  color: #3b368c !important;
}
/* h4.blue{
    margin: 0 45vw !important;
} */
#contact-us {
  background-image: linear-gradient(to right, #3c3c3da9, #3c3c3daf), url("/img/ecommerce.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.demo-request i {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 20px;
}
.fas:hover {
  cursor: pointer;
  transform: rotate(60deg);
}

#contact-us p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
#contact-us.button,
a.button,
span.button {
  background-color: #e66255 !important;
  border-color: #e66255 !important;
  color: #fff !important;
  width: 100%;
  max-width: 250px;
}
.demo-request.butt,
a.butt,
span.butt {
  background-color: #1297c3 !important;
  border-color: #1297c3 !important;
  color: #fff !important;
  border-radius: 100px;
  background-color: none;
  padding: 22px 36px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2.4px;
  line-height: 10px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  font-weight: bold;
}
.demo-request.button,
a.butt,
span.butt:hover {
  background-color: #1297c3d8 !important ;
}

#contact-us.button,
a.button,
span.button {
  background-color: #e66255 !important;
  border-color: #e66255 !important;
  color: #fff !important;
  border-radius: 100px;
  background-color: none;
  padding: 22px 36px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2.4px;
  line-height: 10px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  font-weight: bold;
  margin: 5px;
}
#contact-us.button,
a.button,
span.button:hover {
  background-color: #fff !important ;
  color: #e66255 !important;
}
.footer {
  color: white;
  background-color: #000;
}

.container-4 {
  padding: 10vh 0;
}
.container-3 {
  margin: 20vh 8vw !important;
}
.container-5 {
  margin: 6vh 4vw !important;
}
.container-8 {
  padding: 10vh 2vw !important;
  color: white;
  background-color: #000;
}

.container-8 button {
  background-color: #0e153a;
}

.card {
  width: 225px !important;
  margin: 2rem 1rem !important;
  height: 210px !important;
}
.hr-orange {
  width: 30%;
  height: 3px !important;
  margin: 2vh 0 !important;
  background-color: #e66255;
}
.f {
  background-color: #0e153a;
  padding: 2rem !important;
}
input,
textarea {
  margin: 3vh 0 !important;
}
section {
  margin: 2vh 0 !important;
}
.footer-form {
  padding-top: 2vh;
  background-color: #fff;
  color: black;
}
.foot {
  background-color: indigo;
  padding: 1vh 5vw !important;
}

.container-6 {
  /* background-color: #3D5AF1; */
  padding: 3vh 8vw !important;
  color: white;
}
i {
  padding: 8px 1rem 0 0;
  color: #e66255;
}
.d-svg {
  /* background-color: #fff; */
  position: absolute;
  top: -21px !important;
  right: 5px;
  width: 50px;
  height: 50px;
}

img.rounded {
  padding: 0 !important;
  height: 75vh;
}
.my-owl {
  padding: 2rem !important;
}
.my-owl .owl-item {
  margin-left: 2rem !important;
  width: 25% !important;
}
.driver-card {
  padding: 4rem 1rem !important;
  height: 200px;
  position: relative;
}
.ci-card {
  padding-left: 3rem !important;
  position: relative;
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  height: max-content;
  min-height: 100px;
  border-radius: 9px !important;
  min-width: 280px;
  width: 40%;
}
.ci-img {
  height: 346px !important;

  border-radius: 9px !important;
}
.logo {
  height: 40px;
}
ul.nav {
  width: 100% !important;
}
.flip-card {
  background-color: transparent;
  width: 1000px;
  height: 250px;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /*Remove this if you don't want the 3D effect*/
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: none !important;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  /* background-color: rgb(248, 248, 248); */
  color: black;
}

/* Style the back side */
.flip-card-back {
  /* background-color: white; */
  color: white;
  transform: rotateY(180deg);
}
.flip-h {
  margin-top: 1rem !important;
}
#form-request {
  justify-content: center !important;
  width: 100% !important;
}
.colored-7v {
  background: #0e153a;
  height: 200px;
  /* margin-left: -7rem !important;
  margin-right: -7rem !important; */
  padding-left: 7rem;
  padding-top: 3rem;
  margin-top: -5rem !important;
}
.flex-7v {
  margin-top: -5rem !important;
  justify-content: center;
}
.width {
  width: 100%;
  /* margin-left: 1rem !important; */
}
.owl-dots {
  display: none;
}
.why-us img {
  height: 300px !important;
  width: 300px !important;
}
.layer {
  background-color: rgba(50, 205, 50, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay-h {
  color: white !important;
  margin-top: 3rem !important;
  margin-bottom: 5rem !important;
}
.color-overlay {
  position: relative;
}
.overlay-img {
  height: 250px !important;
  width: 315px !important;
  margin-bottom: 3rem !important;
}
.overlay-number {
  margin-top: -3rem !important;
  font-size: 6rem !important;
  font-weight: 400 !important;
  /* position: absolute !important; */
  color: orange !important;
  bottom: -23px;
  left: 134px;
}
.overlay-i {
  color: white !important;
}
.overlay-p,
.overlay-h {
  font-weight: 900 !important;
}
.demo-h {
  text-align: center !important;
  font-weight: bold;
  color: white;
  width: 100%;
  margin: 1rem 0 !important;
}
.c9-img {
  height: 350px !important;
  width: 300px !important;
  margin-bottom: 3rem !important;
}
.container-9 i {
  color: black !important;
}
.container-9 .col-lg-7 p {
  margin: 0rem 0rem !important;
}
.container-9 {
  margin-top: 3rem !important;
}
.container-9 .col-lg-7 h2 {
  margin: 1rem !important;
}
.c9-h2 {
  margin-bottom: 5rem !important;
  color: #3b368c !important;
}
.journy-con {
  margin-top: 3rem !important;
}
.journy-con h3 {
  font-size: 1.5rem !important;
}
.container-5 h2,
.container-5 p.text-center.pl-7 {
  color: #3b368c !important;
}
.journy-con p {
  color: #3b368c !important;
}
body {
  background-attachment: fixed;
}
.app-h {
  text-align: center;
  padding-top: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.demo-input::placeholder {
  color: #e66255;
  opacity: 1; /* Firefox */
}
.demo-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}
.demo-input,
label {
  background-color: transparent;
  border-width: 0px 0px 2px 0px;
  border-color: #e66255;
  border-style: solid;
  border-radius: 0px;
  padding: 18px 24px;
  color: #ffffff !important;
  letter-spacing: 2.4px;
  font-size: 18px;
  text-align: left;
  border-width: 0px 0px 2px 0px;
  border-color: #e66255;
  border-style: solid;
  border-radius: 0px;
  padding: 18px 24px;
  color: #ffffff;
  letter-spacing: 2.4px;
  font-size: 15px;
  text-align: left;
  width: 270px !important;
  justify-content: center !important;
  transition: none !important;
}

label {
  color: #fff !important;
  font-weight: bold;
  width: max-content !important;
}
.demo-input:focus {
  border: none;
}

.footer-logo {
  height: 60px !important;
  width: 230px !important;
}
a {
  text-decoration: none !important;
  color: black !important;
  /* margin-top: 0.5rem !important;
    margin-bottom: 2rem !important; */
  display: inline-block;
}
a:hover {
  color: #e66255 !important;
}
a.nav-link {
  margin-top: 0 !important;
  margin-bottom: 0rem !important;
}
.flex-fill p.text-left {
  margin: 0 !important;
}
.custom1 {
  padding-bottom: 2rem !important;
}
a#footer-a {
  display: inline-block;
  margin: 0 !important;
  color: white !important;
}
p#footer-p {
  margin: 2vh !important;
  margin-left: 0 !important;
}
a#footer-a:hover {
  color: #e66255 !important;
  transition: 0.5s;
}
.check-bullet {
  display: inline !important;
  margin: 10px !important;
  height: 15px;
}
.why-us-svg {
  margin-top: 1.5em !important;
}
.BA-h4,
.CI-h4 {
  font-size: 20px !important;
  font-weight: 900 !important;
}
.BA-h4 {
  font-size: 15px !important;
}
.BA-p,
.CI-p {
  font-size: 13px !important;
}
.CI-p {
  margin-top: 0.5rem !important;
}
.icon img {
  height: 62px !important;
  width: 50px !important;
}
.BA-mid-img {
  height: 347px !important;
}
.BA-row {
  justify-content: center;
}
.BA-h2 {
  font-weight: 900 !important;

  color: #3b368c !important;
}
.BA-left {
  margin-right: 1rem !important;
}
.BA-right {
  margin-left: 2rem !important;
}
/* h2{
    font-weight: 700 !important;
}
h3,h4, .v-h-7{
font-weight: 900 !important;
}
h5{
    font-weight: 900 !important ;
} */
.CI-icon {
  background-color: white;
  height: 54px;
  position: absolute;
  left: -22px;
  top: 16px;
  border-radius: 50%;
  width: 54px;
  padding-left: 8px;
  padding-top: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.CI-icon img {
  height: 26px !important;
  margin-left: 5px !important;
  width: 26px !important;
  margin-top: 12px !important;
}
.CI-h4 {
  font-size: 15px !important;
}
.orange {
  color: #ff8a00;
}
.why-us-h3 {
  color: #3b368c !important;
  /* margin-bottom: 8rem !important; */
}

/* --------CONTACT-US---------*/

.modal-content {
  background-color: transparent;
  background-image: linear-gradient(-180deg, #524269 0%, #0e153a 0%, #0e153a 80%, #0e153a 100%);
}
#contact-us.demo {
  text-align: center;
  justify-content: center;
}
#contact-us h4 {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3.6px;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
  text-align: start;
}

#contact-us h1 {
  text-align: center;
  font-size: 2rem !important;
  font-weight: 700;
  color: #fff;
  margin: none !important;
}

#contact-us p {
  color: #0e153a;
  font-weight: 300;
}
#contact-us h3 {
  color: #e66255;
  font-size: 20px !important;
}

/* --------CONTACT-US---------*/

.footer a {
  color: #fff !important;
  font-weight: bold;
}
.footer a:hover {
  color: #e66255 !important;
  font-weight: bold;
}
.footer-links {
  font-size: 15px;
  font-weight: bold !important;
  color: #e66255;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.btn-primary.btn {
  background-color: #fff !important;

  color: #e66255;
  font-weight: 500;
  font-size: 0.9rem;
  border-radius: 5%;
}

.btn-primary.btn:hover {
  background-color: #e66255 !important;
  color: #fff;
}

.btn-primary {
  background-color: #e66255 !important;
  border-color: #e66255 !important;
}

.P-left-img {
  position: relative !important;
}
.custom1 .right-para {
  width: 0rem !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none !important;
}
.ba-details {
  width: 60%;
  text-align: center;
}
.ba-title {
  font-weight: 900;
  font-size: 34px;
}
.ba-an-img {
  width: 40%;
}
.mc-an-img {
  width: 35%;
}
.ba-detail-left {
  width: 50%;
  margin-left: 20px;
}
.ba-detail-right {
  width: 50%;
  margin-left: 20px;
}
.ba-details-icon {
  width: 50px;
  height: 50px;
}
.ba-details-title {
  font-size: 20px;
}
.ci-details-width {
  width: 80%;
}
.ba-details-width {
  width: 80%;
}
.sv-details-width {
  width: 80%;
}
.fps-details-width {
  width: 80%;
}
.sv-details-img {
  width: 45%;
}
.comp-l-r-padding {
  padding-left: 50px;
  padding-right: 50px;
}
.ci-bg {
  background-image: url("/img/bg-brand.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.sv-box-bg {
  background-image: url("/img/seven-v-bg.svg");
  background-position: right top;
  background-repeat: no-repeat;
}
.sv-main-box-title {
  font-weight: 900;
  font-size: 26px;
}
.flip-card-front > p {
  font-size: 26px;
}
.flip-card-back > p {
  font-size: 16px;
}

.product-disp {
  width: 75px;
  height: 75px;
  margin: auto;
  /* box-shadow: 0px 0px 5px #cccccc; */
}
.product-sub-disp {
  height: 100%;
  width: 100%;
}
.product-logo-container {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.fp-main-details {
  margin-left: 50px;
  /* background: rgba(255, 255, 255, 0.1); */
  border-radius: 5px;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .fp-main-details {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.react-slideshow-container .default-nav {
  /* display: none !important; */
  background-color: transparent !important;
}
/* .react-slideshow-container .default-nav span{
  border-color: #fff!important;
} */

.react-slideshow-container .default-nav:first-of-type {
  margin-right: -5px !important;
}
.react-slideshow-container .default-nav:last-of-type {
  margin-left: 0px !important;
}

.trapezoid-top {
  border-top: 90px solid#4EA8DD;
  width: 225px;
}
.trapezoid-mid {
  border-top: 90px solid#4EA8DD;
  width: 200px;
}
.trapezoid-bottom {
  border-top: 90px solid #4ea8dd;
  width: 175px;
}
.trapezoid-box-br-l-r {
  border-left: 12.5px solid transparent !important;
  border-right: 12.5px solid transparent !important;
}
.trapezoid-active {
  border-top: 90px solid #044063 !important;
}
#TPmc0,
#TPmc1,
#TPmc2 {
  width: 225px;
  /* height: 200px; */
  position: relative;
  margin: 20px;
  opacity: 0.1;
}
#map-cons-bot-box {
  z-index: 9;
  /* margin: 20px; for demo purpose  */
}
#map-cons-top-box {
  width: 100%;
  /* height: 100%; */
  position: absolute;
  top: 25%;
  background-color: transparent;
  /* left: 25%; */
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  /* opacity: 0.8; for demo purpose  */
}

.mc-main-details {
  margin-left: 2px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.mc-box-title {
  text-align: start;
  color: #000;
  font-family: sans-serif;
  font-size: 22px;
  font-weight: 900;
}
.MAPC-bg {
  background: url("/img/dd-analysis-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
