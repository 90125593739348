@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&family=Poppins:wght@300&display=swap);
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif !important  ;
}
.carousel-item {
  height: 100vh;
  overflow: hidden;
  background-size: cover;
}
.top-nav-collapse {
  background-color: #0e153a !important;
}
.navpad {
  margin-right: 50px;
}
.carousel-caption h3 {
  padding-top: 0rem;
  text-align: left;
  font-size: 4rem;
  width: 50vw;
  margin-top: 10%;
  font-weight: 600;
}
.carousel-caption {
  top: 25%;
  text-align: start !important;
}
.carousel-caption > span {
  font-size: 26px;
  font-weight: 400;
  text-align: start;
}

.navbar {
  box-shadow: none !important;
}

nav ul li a {
  display: block;
  /* padding: 10px; */
  text-decoration: none;
  color: #aaa;
  font-weight: 800;
  text-transform: uppercase;
  margin-left: 10px;
}

.navabr-logo {
  margin-left: 200px !important;
  cursor: pointer;
  width: 20px;
}

.item {
  margin: 20px;
  height: 100px !important ;
  display: flex;
}

.brands {
  width: 20px !important;
}

.foot {
  width: 100%;
}
/* 
h1,h2,h3,h4,h5{
    
    font-family:'Montserrat', sans-serif !important;
} */
body {
  margin: 0;
}
/* p, h2{
  
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
} */
/* p{
    color: #E66255;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
} */
.wave-container {
  width: 100vw;
  /* position: relative; */
  color: #fff;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-attachment: fixed; */
  background-size: cover;
  /* height:80vh !important; */
  overflow: hidden;
}
.top-carousel .wave-container {
  height: 80vh !important;
}
.gradient {
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), rgba(255, 0, 0, 1));
  width: 30rem;

  height: 10rem;
}
.container {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}
.container {
  max-width: 100vw !important;
}
main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  margin: 0 !important;
}
.top-h1,
.top-p {
  padding-top: 0rem;
  text-align: left;
  width: 48vw;
  padding-left: 3rem !important;
  height: 76px;
}
.top-p {
  font-weight: 300 !important;
}
.top-h1 {
  margin-top: 6rem !important;
  font-weight: 900 !important;
}
.top-btn {
  position: absolute;
  left: 3rem;
}
.container-2,
.container-4 {
  text-align: center;
  margin: 3rem 0 !important;
}
.flex {
  display: flex;
  margin-top: 3rem !important;
}
.owl-brand {
  margin-top: 3rem !important;
}
.brands {
  height: 85px;
  width: 50px !important;
  margin: auto !important;
}
.blue {
  background-color: #add8e6;
  color: blue;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.container-3 {
  margin-top: 35vh !important;
}
.left {
  padding-top: 20vh !important;
}
.left_para {
  width: 80% !important;
}
.services_p {
  padding: 0 10vw !important;
  margin-bottom: 5rem !important;
  color: #3b368c !important;
}
/* h4.blue{
    margin: 0 45vw !important;
} */
#contact-us {
  background-image: linear-gradient(to right, #3c3c3da9, #3c3c3daf), url("/img/ecommerce.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.demo-request i {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 20px;
}
.fas:hover {
  cursor: pointer;
  transform: rotate(60deg);
}

#contact-us p {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}
#contact-us.button,
a.button,
span.button {
  background-color: #e66255 !important;
  border-color: #e66255 !important;
  color: #fff !important;
  width: 100%;
  max-width: 250px;
}
.demo-request.butt,
a.butt,
span.butt {
  background-color: #1297c3 !important;
  border-color: #1297c3 !important;
  color: #fff !important;
  border-radius: 100px;
  background-color: none;
  padding: 22px 36px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2.4px;
  line-height: 10px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  font-weight: bold;
}
.demo-request.button,
a.butt,
span.butt:hover {
  background-color: #1297c3d8 !important ;
}

#contact-us.button,
a.button,
span.button {
  background-color: #e66255 !important;
  border-color: #e66255 !important;
  color: #fff !important;
  border-radius: 100px;
  background-color: none;
  padding: 22px 36px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2.4px;
  line-height: 10px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  font-weight: bold;
  margin: 5px;
}
#contact-us.button,
a.button,
span.button:hover {
  background-color: #fff !important ;
  color: #e66255 !important;
}
.footer {
  color: white;
  background-color: #000;
}

.container-4 {
  padding: 10vh 0;
}
.container-3 {
  margin: 20vh 8vw !important;
}
.container-5 {
  margin: 6vh 4vw !important;
}
.container-8 {
  padding: 10vh 2vw !important;
  color: white;
  background-color: #000;
}

.container-8 button {
  background-color: #0e153a;
}

.card {
  width: 225px !important;
  margin: 2rem 1rem !important;
  height: 210px !important;
}
.hr-orange {
  width: 30%;
  height: 3px !important;
  margin: 2vh 0 !important;
  background-color: #e66255;
}
.f {
  background-color: #0e153a;
  padding: 2rem !important;
}
input,
textarea {
  margin: 3vh 0 !important;
}
section {
  margin: 2vh 0 !important;
}
.footer-form {
  padding-top: 2vh;
  background-color: #fff;
  color: black;
}
.foot {
  background-color: indigo;
  padding: 1vh 5vw !important;
}

.container-6 {
  /* background-color: #3D5AF1; */
  padding: 3vh 8vw !important;
  color: white;
}
i {
  padding: 8px 1rem 0 0;
  color: #e66255;
}
.d-svg {
  /* background-color: #fff; */
  position: absolute;
  top: -21px !important;
  right: 5px;
  width: 50px;
  height: 50px;
}

img.rounded {
  padding: 0 !important;
  height: 75vh;
}
.my-owl {
  padding: 2rem !important;
}
.my-owl .owl-item {
  margin-left: 2rem !important;
  width: 25% !important;
}
.driver-card {
  padding: 4rem 1rem !important;
  height: 200px;
  position: relative;
}
.ci-card {
  padding-left: 3rem !important;
  position: relative;
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-height: 100px;
  border-radius: 9px !important;
  min-width: 280px;
  width: 40%;
}
.ci-img {
  height: 346px !important;

  border-radius: 9px !important;
}
.logo {
  height: 40px;
}
ul.nav {
  width: 100% !important;
}
.flip-card {
  background-color: transparent;
  width: 1000px;
  height: 250px;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /*Remove this if you don't want the 3D effect*/
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: none !important;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  /* background-color: rgb(248, 248, 248); */
  color: black;
}

/* Style the back side */
.flip-card-back {
  /* background-color: white; */
  color: white;
  transform: rotateY(180deg);
}
.flip-h {
  margin-top: 1rem !important;
}
#form-request {
  justify-content: center !important;
  width: 100% !important;
}
.colored-7v {
  background: #0e153a;
  height: 200px;
  /* margin-left: -7rem !important;
  margin-right: -7rem !important; */
  padding-left: 7rem;
  padding-top: 3rem;
  margin-top: -5rem !important;
}
.flex-7v {
  margin-top: -5rem !important;
  justify-content: center;
}
.width {
  width: 100%;
  /* margin-left: 1rem !important; */
}
.owl-dots {
  display: none;
}
.why-us img {
  height: 300px !important;
  width: 300px !important;
}
.layer {
  background-color: rgba(50, 205, 50, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay-h {
  color: white !important;
  margin-top: 3rem !important;
  margin-bottom: 5rem !important;
}
.color-overlay {
  position: relative;
}
.overlay-img {
  height: 250px !important;
  width: 315px !important;
  margin-bottom: 3rem !important;
}
.overlay-number {
  margin-top: -3rem !important;
  font-size: 6rem !important;
  font-weight: 400 !important;
  /* position: absolute !important; */
  color: orange !important;
  bottom: -23px;
  left: 134px;
}
.overlay-i {
  color: white !important;
}
.overlay-p,
.overlay-h {
  font-weight: 900 !important;
}
.demo-h {
  text-align: center !important;
  font-weight: bold;
  color: white;
  width: 100%;
  margin: 1rem 0 !important;
}
.c9-img {
  height: 350px !important;
  width: 300px !important;
  margin-bottom: 3rem !important;
}
.container-9 i {
  color: black !important;
}
.container-9 .col-lg-7 p {
  margin: 0rem 0rem !important;
}
.container-9 {
  margin-top: 3rem !important;
}
.container-9 .col-lg-7 h2 {
  margin: 1rem !important;
}
.c9-h2 {
  margin-bottom: 5rem !important;
  color: #3b368c !important;
}
.journy-con {
  margin-top: 3rem !important;
}
.journy-con h3 {
  font-size: 1.5rem !important;
}
.container-5 h2,
.container-5 p.text-center.pl-7 {
  color: #3b368c !important;
}
.journy-con p {
  color: #3b368c !important;
}
body {
  background-attachment: fixed;
}
.app-h {
  text-align: center;
  padding-top: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.demo-input::-webkit-input-placeholder {
  color: #e66255;
  opacity: 1; /* Firefox */
}

.demo-input:-ms-input-placeholder {
  color: #e66255;
  opacity: 1; /* Firefox */
}

.demo-input::-ms-input-placeholder {
  color: #e66255;
  opacity: 1; /* Firefox */
}

.demo-input::placeholder {
  color: #e66255;
  opacity: 1; /* Firefox */
}
.demo-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}
.demo-input,
label {
  background-color: transparent;
  border-width: 0px 0px 2px 0px;
  border-color: #e66255;
  border-style: solid;
  border-radius: 0px;
  padding: 18px 24px;
  color: #ffffff !important;
  letter-spacing: 2.4px;
  font-size: 18px;
  text-align: left;
  border-width: 0px 0px 2px 0px;
  border-color: #e66255;
  border-style: solid;
  border-radius: 0px;
  padding: 18px 24px;
  color: #ffffff;
  letter-spacing: 2.4px;
  font-size: 15px;
  text-align: left;
  width: 270px !important;
  justify-content: center !important;
  transition: none !important;
}

label {
  color: #fff !important;
  font-weight: bold;
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}
.demo-input:focus {
  border: none;
}

.footer-logo {
  height: 60px !important;
  width: 230px !important;
}
a {
  text-decoration: none !important;
  color: black !important;
  /* margin-top: 0.5rem !important;
    margin-bottom: 2rem !important; */
  display: inline-block;
}
a:hover {
  color: #e66255 !important;
}
a.nav-link {
  margin-top: 0 !important;
  margin-bottom: 0rem !important;
}
.flex-fill p.text-left {
  margin: 0 !important;
}
.custom1 {
  padding-bottom: 2rem !important;
}
a#footer-a {
  display: inline-block;
  margin: 0 !important;
  color: white !important;
}
p#footer-p {
  margin: 2vh !important;
  margin-left: 0 !important;
}
a#footer-a:hover {
  color: #e66255 !important;
  transition: 0.5s;
}
.check-bullet {
  display: inline !important;
  margin: 10px !important;
  height: 15px;
}
.why-us-svg {
  margin-top: 1.5em !important;
}
.BA-h4,
.CI-h4 {
  font-size: 20px !important;
  font-weight: 900 !important;
}
.BA-h4 {
  font-size: 15px !important;
}
.BA-p,
.CI-p {
  font-size: 13px !important;
}
.CI-p {
  margin-top: 0.5rem !important;
}
.icon img {
  height: 62px !important;
  width: 50px !important;
}
.BA-mid-img {
  height: 347px !important;
}
.BA-row {
  justify-content: center;
}
.BA-h2 {
  font-weight: 900 !important;

  color: #3b368c !important;
}
.BA-left {
  margin-right: 1rem !important;
}
.BA-right {
  margin-left: 2rem !important;
}
/* h2{
    font-weight: 700 !important;
}
h3,h4, .v-h-7{
font-weight: 900 !important;
}
h5{
    font-weight: 900 !important ;
} */
.CI-icon {
  background-color: white;
  height: 54px;
  position: absolute;
  left: -22px;
  top: 16px;
  border-radius: 50%;
  width: 54px;
  padding-left: 8px;
  padding-top: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.CI-icon img {
  height: 26px !important;
  margin-left: 5px !important;
  width: 26px !important;
  margin-top: 12px !important;
}
.CI-h4 {
  font-size: 15px !important;
}
.orange {
  color: #ff8a00;
}
.why-us-h3 {
  color: #3b368c !important;
  /* margin-bottom: 8rem !important; */
}

/* --------CONTACT-US---------*/

.modal-content {
  background-color: transparent;
  background-image: linear-gradient(-180deg, #524269 0%, #0e153a 0%, #0e153a 80%, #0e153a 100%);
}
#contact-us.demo {
  text-align: center;
  justify-content: center;
}
#contact-us h4 {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3.6px;
  display: block;
  -webkit-margin-before: 1.33em;
          margin-block-start: 1.33em;
  -webkit-margin-after: 1.33em;
          margin-block-end: 1.33em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
  text-align: start;
}

#contact-us h1 {
  text-align: center;
  font-size: 2rem !important;
  font-weight: 700;
  color: #fff;
  margin: none !important;
}

#contact-us p {
  color: #0e153a;
  font-weight: 300;
}
#contact-us h3 {
  color: #e66255;
  font-size: 20px !important;
}

/* --------CONTACT-US---------*/

.footer a {
  color: #fff !important;
  font-weight: bold;
}
.footer a:hover {
  color: #e66255 !important;
  font-weight: bold;
}
.footer-links {
  font-size: 15px;
  font-weight: bold !important;
  color: #e66255;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.btn-primary.btn {
  background-color: #fff !important;

  color: #e66255;
  font-weight: 500;
  font-size: 0.9rem;
  border-radius: 5%;
}

.btn-primary.btn:hover {
  background-color: #e66255 !important;
  color: #fff;
}

.btn-primary {
  background-color: #e66255 !important;
  border-color: #e66255 !important;
}

.P-left-img {
  position: relative !important;
}
.custom1 .right-para {
  width: 0rem !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none !important;
}
.ba-details {
  width: 60%;
  text-align: center;
}
.ba-title {
  font-weight: 900;
  font-size: 34px;
}
.ba-an-img {
  width: 40%;
}
.mc-an-img {
  width: 35%;
}
.ba-detail-left {
  width: 50%;
  margin-left: 20px;
}
.ba-detail-right {
  width: 50%;
  margin-left: 20px;
}
.ba-details-icon {
  width: 50px;
  height: 50px;
}
.ba-details-title {
  font-size: 20px;
}
.ci-details-width {
  width: 80%;
}
.ba-details-width {
  width: 80%;
}
.sv-details-width {
  width: 80%;
}
.fps-details-width {
  width: 80%;
}
.sv-details-img {
  width: 45%;
}
.comp-l-r-padding {
  padding-left: 50px;
  padding-right: 50px;
}
.ci-bg {
  background-image: url("/img/bg-brand.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.sv-box-bg {
  background-image: url("/img/seven-v-bg.svg");
  background-position: right top;
  background-repeat: no-repeat;
}
.sv-main-box-title {
  font-weight: 900;
  font-size: 26px;
}
.flip-card-front > p {
  font-size: 26px;
}
.flip-card-back > p {
  font-size: 16px;
}

.product-disp {
  width: 75px;
  height: 75px;
  margin: auto;
  /* box-shadow: 0px 0px 5px #cccccc; */
}
.product-sub-disp {
  height: 100%;
  width: 100%;
}
.product-logo-container {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.fp-main-details {
  margin-left: 50px;
  /* background: rgba(255, 255, 255, 0.1); */
  border-radius: 5px;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .fp-main-details {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.react-slideshow-container .default-nav {
  /* display: none !important; */
  background-color: transparent !important;
}
/* .react-slideshow-container .default-nav span{
  border-color: #fff!important;
} */

.react-slideshow-container .default-nav:first-of-type {
  margin-right: -5px !important;
}
.react-slideshow-container .default-nav:last-of-type {
  margin-left: 0px !important;
}

.trapezoid-top {
  border-top: 90px solid#4EA8DD;
  width: 225px;
}
.trapezoid-mid {
  border-top: 90px solid#4EA8DD;
  width: 200px;
}
.trapezoid-bottom {
  border-top: 90px solid #4ea8dd;
  width: 175px;
}
.trapezoid-box-br-l-r {
  border-left: 12.5px solid transparent !important;
  border-right: 12.5px solid transparent !important;
}
.trapezoid-active {
  border-top: 90px solid #044063 !important;
}
#TPmc0,
#TPmc1,
#TPmc2 {
  width: 225px;
  /* height: 200px; */
  position: relative;
  margin: 20px;
  opacity: 0.1;
}
#map-cons-bot-box {
  z-index: 9;
  /* margin: 20px; for demo purpose  */
}
#map-cons-top-box {
  width: 100%;
  /* height: 100%; */
  position: absolute;
  top: 25%;
  background-color: transparent;
  /* left: 25%; */
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  /* opacity: 0.8; for demo purpose  */
}

.mc-main-details {
  margin-left: 2px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.mc-box-title {
  text-align: start;
  color: #000;
  font-family: sans-serif;
  font-size: 22px;
  font-weight: 900;
}
.MAPC-bg {
  background: url("/img/dd-analysis-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

/*
AUTHOR : ANANYA NAG (@ananay-nag)
COPYRIGHT : ANANYA NAG (@ananay-nag)
*/
/*_COMMON*/
/*APP_BASE*/
.app-max-width {
  max-width: 1368px;
}
.app-width {
  width: -webkit-fill-available;
}
.app-height {
  height: -webkit-fill-available;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  min-height: 100vh;
}
.app-bg-c {
  background-color: #fff !important;
}
/*DISPLAY*/
.display-flex {
  display: flex;
}
.display-flex-imp {
  display: flex !important;
}
.display-block {
  display: block;
}
.display-none {
  display: none;
}
.display-grid {
  display: grid;
  display: -moz-grid;
}
.fl-wrap-wrap {
  flex-wrap: wrap;
}
/*JUSTIFY*/
.jc-center {
  justify-content: center;
}
.jc-flex-end {
  justify-content: flex-end;
}
.jc-space-around{
  justify-content: space-around;
}
/*WIDTH*/
.width-5 {
  width: 5px;
}
.width-10 {
  width: 10px;
}
.width-15 {
  width: 15px;
}
.width-20 {
  width: 20px;
}
.width-25 {
  width: 25px;
}
.width-30 {
  width: 30px;
}
.width-35 {
  width: 35px;
}
.width-40 {
  width: 40px;
}
.width-45 {
  width: 45px;
}
.width-50 {
  width: 50px;
}
.width-55 {
  width: 55px;
}
.width-60 {
  width: 60px;
}
.width-65 {
  width: 65px;
}
.width-70 {
  width: 70px;
}
.width-75 {
  width: 75px;
}
.width-80 {
  width: 80px;
}
.width-85 {
  width: 85px;
}
.width-90 {
  width: 90px;
}
.width-95 {
  width: 95px;
}
.width-100 {
  width: 100px;
}
.width-400 {
  width: 400px;
}
.max-width-400 {
  max-width: 400px;
}
.max-width-50-p {
  max-width: 50%;
}
.max-width-60-p {
  max-width: 60%;
}
.width-5-p {
  width: 5%;
}
.width-10-p {
  width: 10%;
}
.width-15-p {
  width: 15%;
}
.width-20-p {
  width: 20%;
}
.width-25-p {
  width: 25%;
}
.width-30-p {
  width: 30%;
}
.width-35-p {
  width: 35%;
}
.width-40-p {
  width: 40%;
}
.width-45-p {
  width: 45%;
}
.width-50-p {
  width: 50%;
}
.width-55-p {
  width: 55%;
}
.width-60-p {
  width: 60%;
}
.width-65-p {
  width: 65%;
}
.width-70-p {
  width: 70%;
}
.width-75-p {
  width: 75%;
}
.width-80-p {
  width: 80%;
}
.width-85-p {
  width: 85%;
}
.width-90-p {
  width: 90%;
}
.width-95-p {
  width: 95%;
}
.width-100-p {
  width: 100%;
}

/*HEIGHT*/
.height-10vh {
  height: 10vh;
}
.height-15vh {
  height: 15vh;
}
.height-20vh {
  height: 20vh;
}
.height-25vh {
  height: 25vh;
}
.height-30vh {
  height: 30vh;
}
.height-35vh {
  height: 35vh;
}
.height-40vh {
  height: 40vh;
}
.height-45vh {
  height: 45vh;
}
.height-50vh {
  height: 50vh;
}
.height-55vh {
  height: 55vh;
}
.height-60vh {
  height: 60vh;
}
.height-65vh {
  height: 65vh;
}
.height-70vh {
  height: 70vh;
}
.height-75vh {
  height: 75vh;
}
.height-80vh {
  height: 80vh;
}
.height-85vh {
  height: 85vh;
}
.height-90vh {
  height: 90vh;
}
.height-95vh {
  height: 95vh;
}
.height-100vh {
  height: 100vh;
}

.height-10 {
  height: 10px;
}
.height-15 {
  height: 15px;
}
.height-20 {
  height: 20px;
}
.height-25 {
  height: 25px;
}
.height-30 {
  height: 30px;
}
.height-35 {
  height: 35px;
}
.height-40 {
  height: 40px;
}
.height-45 {
  height: 45px;
}
.height-50 {
  height: 50px;
}
.height-55 {
  height: 55px;
}
.height-60 {
  height: 60px;
}
.height-65 {
  height: 65px;
}
.height-70 {
  height: 70px;
}
.height-75 {
  height: 75px;
}
.height-80 {
  height: 80px;
}
.height-85 {
  height: 85px;
}
.height-90 {
  height: 90px;
}
.height-95 {
  height: 95px;
}
.height-100 {
  height: 100px;
}
.height-300 {
  height: 300px;
}
.height-250 {
  height: 250px;
}
.height-400 {
  height: 400px;
}
.height-100-p {
  height: 100%;
}

/*MARGIN TOP LEFT RIGHT BOTTOM ALL*/
.margin-auto {
  margin: auto;
}
.margin-unset {
  margin: unset;
}
.m-l-auto {
  margin-left: auto;
}
.m-r-auto {
  margin-right: auto;
}
.m-t-auto {
  margin-top: auto;
}
.m-b-auto {
  margin-bottom: auto;
}
.m-l-5 {
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-l-55 {
  margin-left: 55px;
}
.m-l-60 {
  margin-left: 60px;
}
.m-l-65 {
  margin-left: 65px;
}
.m-l-70 {
  margin-left: 70px;
}
.m-l-75 {
  margin-left: 75px;
}
.m-l-80 {
  margin-left: 80px;
}
.m-l-85 {
  margin-left: 85px;
}
.m-l-90 {
  margin-left: 90px;
}
.m-l-95 {
  margin-left: 95px;
}
.m-l-100 {
  margin-left: 100px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-r-35 {
  margin-right: 35px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-r-45 {
  margin-right: 45px;
}
.m-r-50 {
  margin-right: 50px;
}
.m-r-55 {
  margin-right: 55px;
}
.m-r-60 {
  margin-right: 60px;
}
.m-r-65 {
  margin-right: 65px;
}
.m-r-70 {
  margin-right: 70px;
}
.m-r-75 {
  margin-right: 75px;
}
.m-r-80 {
  margin-right: 80px;
}
.m-r-85 {
  margin-right: 85px;
}
.m-r-90 {
  margin-right: 90px;
}
.m-r-95 {
  margin-right: 95px;
}
.m-r-100 {
  margin-right: 100px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-t-55 {
  margin-top: 55px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-t-65 {
  margin-top: 65px;
}
.m-t-70 {
  margin-top: 70px;
}
.m-t-75 {
  margin-top: 75px;
}
.m-t-80 {
  margin-top: 80px;
}
.m-t-85 {
  margin-top: 85px;
}
.m-t-90 {
  margin-top: 90px;
}
.m-t-95 {
  margin-top: 95px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-b-55 {
  margin-bottom: 55px;
}
.m-b-60 {
  margin-bottom: 60px;
}
.m-b-65 {
  margin-bottom: 65px;
}
.m-b-70 {
  margin-bottom: 70px;
}
.m-b-75 {
  margin-bottom: 75px;
}
.m-b-80 {
  margin-bottom: 80px;
}
.m-b-85 {
  margin-bottom: 85px;
}
.m-b-90 {
  margin-bottom: 90px;
}
.m-b-95 {
  margin-bottom: 95px;
}
.m-b-100 {
  margin-bottom: 100px;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-25 {
  margin: 25px;
}
.m-30 {
  margin: 30px;
}
.m-35 {
  margin: 35px;
}
.m-40 {
  margin: 40px;
}
.m-45 {
  margin: 45px;
}
.m-50 {
  margin: 50px;
}
.m-55 {
  margin: 55px;
}
.m-60 {
  margin: 60px;
}
.m-65 {
  margin: 65px;
}
.m-70 {
  margin: 70px;
}
.m-75 {
  margin: 75px;
}
.m-80 {
  margin: 80px;
}
.m-85 {
  margin: 85px;
}
.m-90 {
  margin: 90px;
}
.m-95 {
  margin: 95px;
}
.m-100 {
  margin: 100px;
}

.m-t-10-p{
  margin-top: 10%;
}

/*PADDING TOP BOTTOM LEFT RIGHT ALL*/
.p-l-5 {
  padding-left: 5px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-l-55 {
  padding-left: 55px;
}
.p-l-60 {
  padding-left: 60px;
}
.p-l-65 {
  padding-left: 65px;
}
.p-l-70 {
  padding-left: 70px;
}
.p-l-75 {
  padding-left: 75px;
}
.p-l-80 {
  padding-left: 80px;
}
.p-l-85 {
  padding-left: 85px;
}
.p-l-90 {
  padding-left: 90px;
}
.p-l-95 {
  padding-left: 95px;
}
.p-l-100 {
  padding-left: 100px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-r-35 {
  padding-right: 35px;
}
.p-r-40 {
  padding-right: 40px;
}
.p-r-45 {
  padding-right: 45px;
}
.p-r-50 {
  padding-right: 50px;
}
.p-r-55 {
  padding-right: 55px;
}
.p-r-60 {
  padding-right: 60px;
}
.p-r-65 {
  padding-right: 65px;
}
.p-r-70 {
  padding-right: 70px;
}
.p-r-75 {
  padding-right: 75px;
}
.p-r-80 {
  padding-right: 80px;
}
.p-r-85 {
  padding-right: 85px;
}
.p-r-90 {
  padding-right: 90px;
}
.p-r-95 {
  padding-right: 95px;
}
.p-r-100 {
  padding-right: 100px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-t-55 {
  padding-top: 55px;
}
.p-t-60 {
  padding-top: 60px;
}
.p-t-65 {
  padding-top: 65px;
}
.p-t-70 {
  padding-top: 70px;
}
.p-t-75 {
  padding-top: 75px;
}
.p-t-80 {
  padding-top: 80px;
}
.p-t-85 {
  padding-top: 85px;
}
.p-t-90 {
  padding-top: 90px;
}
.p-t-95 {
  padding-top: 95px;
}
.p-t-100 {
  padding-top: 100px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-b-55 {
  padding-bottom: 55px;
}
.p-b-60 {
  padding-bottom: 60px;
}
.p-b-65 {
  padding-bottom: 65px;
}
.p-b-70 {
  padding-bottom: 70px;
}
.p-b-75 {
  padding-bottom: 75px;
}
.p-b-80 {
  padding-bottom: 80px;
}
.p-b-85 {
  padding-bottom: 85px;
}
.p-b-90 {
  padding-bottom: 90px;
}
.p-b-95 {
  padding-bottom: 95px;
}
.p-b-100 {
  padding-bottom: 100px;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}
.p-35 {
  padding: 35px;
}
.p-40 {
  padding: 40px;
}
.p-45 {
  padding: 45px;
}
.p-50 {
  padding: 50px;
}
.p-55 {
  padding: 55px;
}
.p-60 {
  padding: 60px;
}
.p-65 {
  padding: 65px;
}
.p-70 {
  padding: 70px;
}
.p-75 {
  padding: 75px;
}
.p-80 {
  padding: 80px;
}
.p-85 {
  padding: 85px;
}
.p-90 {
  padding: 90px;
}
.p-95 {
  padding: 95px;
}
.p-100 {
  padding: 100px;
}

/*COLORS*/

.c-000000 {
  color: #000000;
}
.c-333333 {
  color: #333333;
}
.c-666666 {
  color: #666666;
}
.c-999999 {
  color: #999999;
}
.c-CCCCCC {
  color: #cccccc;
}
.c-FFFFFF {
  color: #ffffff;
}
.c-996666 {
  color: #996666;
}
.c-663333 {
  color: #663333;
}
.c-CC9999 {
  color: #cc9999;
}
.c-993333 {
  color: #993333;
}
.c-CC6666 {
  color: #cc6666;
}
.c-CC3333 {
  color: #cc3333;
}
.c-330000 {
  color: #330000;
}
.c-660000 {
  color: #660000;
}
.c-990000 {
  color: #990000;
}
.c-CC0000 {
  color: #cc0000;
}
.c-3B368C{
  color: #3B368C;
}
.c-FF0000 {
  color: #ff0000;
}
.c-FF8A00{
  color:#FF8A00
}
.c-FF3333 {
  color: #ff3333;
}
.c-FF6666 {
  color: #ff6666;
}
.c-FF9999 {
  color: #ff9999;
}
.c-FFCCCC {
  color: #ffcccc;
}
.c-FF3300 {
  color: #ff3300;
}
.c-CC3300 {
  color: #cc3300;
}
.c-FF6633 {
  color: #ff6633;
}
.c-CC6633 {
  color: #cc6633;
}
.c-993300 {
  color: #993300;
}
.c-FF9966 {
  color: #ff9966;
}
.c-FF6600 {
  color: #ff6600;
}
.c-996633 {
  color: #996633;
}
.c-CC9966 {
  color: #cc9966;
}
.c-663300 {
  color: #663300;
}
.c-CC6600 {
  color: #cc6600;
}
.c-FF9933 {
  color: #ff9933;
}
.c-FFCC99 {
  color: #ffcc99;
}
.c-FF9900 {
  color: #ff9900;
}
.c-CC9933 {
  color: #cc9933;
}
.c-996600 {
  color: #996600;
}
.c-FFCC66 {
  color: #ffcc66;
}
.c-CC9900 {
  color: #cc9900;
}
.c-FFCC33 {
  color: #ffcc33;
}
.c-FFCC00 {
  color: #ffcc00;
}
.c-999966 {
  color: #999966;
}
.c-666633 {
  color: #666633;
}
.c-CCCC99 {
  color: #cccc99;
}
.c-999933 {
  color: #999933;
}
.c-CCCC66 {
  color: #cccc66;
}
.c-CCCC33 {
  color: #cccc33;
}
.c-333300 {
  color: #333300;
}
.c-666600 {
  color: #666600;
}
.c-999900 {
  color: #999900;
}
.c-CCCC00 {
  color: #cccc00;
}
.c-FFFF00 {
  color: #ffff00;
}
.c-FFFF33 {
  color: #ffff33;
}
.c-FFFF66 {
  color: #ffff66;
}
.c-FFFF99 {
  color: #ffff99;
}
.c-FFFFCC {
  color: #ffffcc;
}
.c-CCFF00 {
  color: #ccff00;
}
.c-99CC00 {
  color: #99cc00;
}
.c-CCFF33 {
  color: #ccff33;
}
.c-99CC33 {
  color: #99cc33;
}
.c-969496 {
  color: 969496;
}
.c-669900 {
  color: #669900;
}
.c-CCFF66 {
  color: #ccff66;
}
.c-99FF00 {
  color: #99ff00;
}
.c-669933 {
  color: #669933;
}
.c-99CC66 {
  color: #99cc66;
}
.c-336600 {
  color: #336600;
}
.c-66CC00 {
  color: #66cc00;
}
.c-99FF33 {
  color: #99ff33;
}
.c-CCFF99 {
  color: #ccff99;
}
.c-66FF00 {
  color: #66ff00;
}
.c-66CC33 {
  color: #66cc33;
}
.c-339900 {
  color: #339900;
}
.c-99FF66 {
  color: #99ff66;
}
.c-33CC00 {
  color: #33cc00;
}
.c-66FF33 {
  color: #66ff33;
}
.c-33FF00 {
  color: #33ff00;
}
.c-669966 {
  color: #669966;
}
.c-336633 {
  color: #336633;
}
.c-99CC99 {
  color: #99cc99;
}
.c-339933 {
  color: #339933;
}
.c-66CC66 {
  color: #66cc66;
}
.c-33CC33 {
  color: #33cc33;
}
.c-003300 {
  color: #003300;
}
.c-006600 {
  color: #006600;
}
.c-009900 {
  color: #009900;
}
.c-00CC00 {
  color: #00cc00;
}
.c-00FF00 {
  color: #00ff00;
}
.c-33FF33 {
  color: #33ff33;
}
.c-66FF66 {
  color: #66ff66;
}
.c-99FF99 {
  color: #99ff99;
}
.c-CCFFCC {
  color: #ccffcc;
}
.c-00FF33 {
  color: #00ff33;
}
.c-00CC33 {
  color: #00cc33;
}
.c-33FF66 {
  color: #33ff66;
}
.c-33CC66 {
  color: #33cc66;
}
.c-009933 {
  color: #009933;
}
.c-66FF99 {
  color: #66ff99;
}
.c-00FF66 {
  color: #00ff66;
}
.c-339966 {
  color: #339966;
}
.c-66CC99 {
  color: #66cc99;
}
.c-006633 {
  color: #006633;
}
.c-00CC66 {
  color: #00cc66;
}
.c-33FF99 {
  color: #33ff99;
}
.c-99FFCC {
  color: #99ffcc;
}
.c-00FF99 {
  color: #00ff99;
}
.c-33CC99 {
  color: #33cc99;
}
.c-009966 {
  color: #009966;
}
.c-66FFCC {
  color: #66ffcc;
}
.c-00CC99 {
  color: #00cc99;
}
.c-33FFCC {
  color: #33ffcc;
}
.c-00FFCC {
  color: #00ffcc;
}
.c-669999 {
  color: #669999;
}
.c-336666 {
  color: #336666;
}
.c-99CCCC {
  color: #99cccc;
}
.c-339999 {
  color: #339999;
}
.c-66CCCC {
  color: #66cccc;
}
.c-33CCCC {
  color: #33cccc;
}
.c-003333 {
  color: #003333;
}
.c-006666 {
  color: #006666;
}
.c-009999 {
  color: #009999;
}
.c-00CCCC {
  color: #00cccc;
}
.c-00FFFF {
  color: #00ffff;
}
.c-33FFFF {
  color: #33ffff;
}
.c-66FFFF {
  color: #66ffff;
}
.c-99FFFF {
  color: #99ffff;
}
.c-CCFFFF {
  color: #ccffff;
}
.c-00CCFF {
  color: #00ccff;
}
.c-0099CC {
  color: #0099cc;
}
.c-33CCFF {
  color: #33ccff;
}
.c-3399CC {
  color: #3399cc;
}
.c-006699 {
  color: #006699;
}
.c-66CCFF {
  color: #66ccff;
}
.c-0099FF {
  color: #0099ff;
}
.c-336699 {
  color: #336699;
}
.c-6699CC {
  color: #6699cc;
}
.c-003366 {
  color: #003366;
}
.c-0066CC {
  color: #0066cc;
}
.c-3399FF {
  color: #3399ff;
}
.c-99CCFF {
  color: #99ccff;
}
.c-0066FF {
  color: #0066ff;
}
.c-3366CC {
  color: #3366cc;
}
.c-003399 {
  color: #003399;
}
.c-6699FF {
  color: #6699ff;
}
.c-0033CC {
  color: #0033cc;
}
.c-3366FF {
  color: #3366ff;
}
.c-0033FF {
  color: #0033ff;
}
.c-666699 {
  color: #666699;
}
.c-333366 {
  color: #333366;
}
.c-9999CC {
  color: #9999cc;
}
.c-333399 {
  color: #333399;
}
.c-6666CC {
  color: #6666cc;
}
.c-3333CC {
  color: #3333cc;
}
.c-000033 {
  color: #000033;
}
.c-000066 {
  color: #000066;
}
.c-000099 {
  color: #000099;
}
.c-0000CC {
  color: #0000cc;
}
.c-0000FF {
  color: #0000ff;
}
.c-3333FF {
  color: #3333ff;
}
.c-6666FF {
  color: #6666ff;
}
.c-9999FF {
  color: #9999ff;
}
.c-CCCCFF {
  color: #ccccff;
}
.c-3300FF {
  color: #3300ff;
}
.c-3300CC {
  color: #3300cc;
}
.c-6633FF {
  color: #6633ff;
}
.c-6633CC {
  color: #6633cc;
}
.c-330099 {
  color: #330099;
}
.c-9966FF {
  color: #9966ff;
}
.c-6600FF {
  color: #6600ff;
}
.c-663399 {
  color: #663399;
}
.c-9966CC {
  color: #9966cc;
}
.c-330066 {
  color: #330066;
}
.c-6600CC {
  color: #6600cc;
}
.c-9933FF {
  color: #9933ff;
}
.c-CC99FF {
  color: #cc99ff;
}
.c-9900FF {
  color: #9900ff;
}
.c-9933CC {
  color: #9933cc;
}
.c-660099 {
  color: #660099;
}
.c-CC66FF {
  color: #cc66ff;
}
.c-9900CC {
  color: #9900cc;
}
.c-CC33FF {
  color: #cc33ff;
}
.c-CC00FF {
  color: #cc00ff;
}
.c-996699 {
  color: #996699;
}
.c-663366 {
  color: #663366;
}
.c-CC99CC {
  color: #cc99cc;
}
.c-993399 {
  color: #993399;
}
.c-CC66CC {
  color: #cc66cc;
}
.c-CC33CC {
  color: #cc33cc;
}
.c-330033 {
  color: #330033;
}
.c-660066 {
  color: #660066;
}
.c-990099 {
  color: #990099;
}
.c-CC00CC {
  color: #cc00cc;
}
.c-FF00FF {
  color: #ff00ff;
}
.c-FF33FF {
  color: #ff33ff;
}
.c-FF66FF {
  color: #ff66ff;
}
.c-FF99FF {
  color: #ff99ff;
}
.c-FFCCFF {
  color: #ffccff;
}
.c-FF00CC {
  color: #ff00cc;
}
.c-CC0099 {
  color: #cc0099;
}
.c-FF33CC {
  color: #ff33cc;
}
.c-CC3399 {
  color: #cc3399;
}
.c-990066 {
  color: #990066;
}
.c-FF66CC {
  color: #ff66cc;
}
.c-FF0099 {
  color: #ff0099;
}
.c-993366 {
  color: #993366;
}
.c-CC6699 {
  color: #cc6699;
}
.c-660033 {
  color: #660033;
}
.c-CC0066 {
  color: #cc0066;
}
.c-FF3399 {
  color: #ff3399;
}
.c-FF99CC {
  color: #ff99cc;
}
.c-FF0066 {
  color: #ff0066;
}
.c-CC3366 {
  color: #cc3366;
}
.c-990033 {
  color: #990033;
}
.c-FF6699 {
  color: #ff6699;
}
.c-CC0033 {
  color: #cc0033;
}
.c-FF3366 {
  color: #ff3366;
}
.c-FF0033 {
  color: #ff0033;
}
.c-f7408c {
  color: #f7408c;
}

/*BACKGROUNDS*/
.bg-000000 {
  background-color: #000000;
}
.bg-f3f2f2 {
  background-color: #f3f2f2;
}
.bg-333333 {
  background-color: #333333;
}
.bg-666666 {
  background-color: #666666;
}
.bg-999999 {
  background-color: #999999;
}
.bg-CCCCCC {
  background-color: #cccccc;
}
.bg-FFFFFF {
  background-color: #ffffff;
}
.bg-996666 {
  background-color: #996666;
}
.bg-663333 {
  background-color: #663333;
}
.bg-CC9999 {
  background-color: #cc9999;
}
.bg-993333 {
  background-color: #993333;
}
.bg-CC6666 {
  background-color: #cc6666;
}
.bg-CC3333 {
  background-color: #cc3333;
}
.bg-330000 {
  background-color: #330000;
}
.bg-660000 {
  background-color: #660000;
}
.bg-990000 {
  background-color: #990000;
}
.bg-CC0000 {
  background-color: #cc0000;
}
.bg-FF0000 {
  background-color: #ff0000;
}
.bg-FF3333 {
  background-color: #ff3333;
}
.bg-FF6666 {
  background-color: #ff6666;
}
.bg-FF9999 {
  background-color: #ff9999;
}
.bg-f7408c {
  background-color: #f7408c;
}
.bg-FFCCCC {
  background-color: #ffcccc;
}
.bg-FF3300 {
  background-color: #ff3300;
}
.bg-CC3300 {
  background-color: #cc3300;
}
.bg-FF6633 {
  background-color: #ff6633;
}
.bg-CC6633 {
  background-color: #cc6633;
}
.bg-993300 {
  background-color: #993300;
}
.bg-FF9966 {
  background-color: #ff9966;
}
.bg-FFF5FA {
  background-color: #fff5fa;
}
.bg-fbdcea {
  background-color: #fbdcea;
}
.bg-FF6600 {
  background-color: #ff6600;
}
.bg-996633 {
  background-color: #996633;
}
.bg-CC9966 {
  background-color: #cc9966;
}
.bg-663300 {
  background-color: #663300;
}
.bg-CC6600 {
  background-color: #cc6600;
}
.bg-FF9933 {
  background-color: #ff9933;
}
.bg-FFCC99 {
  background-color: #ffcc99;
}
.bg-FF9900 {
  background-color: #ff9900;
}
.bg-CC9933 {
  background-color: #cc9933;
}
.bg-996600 {
  background-color: #996600;
}
.bg-FFCC66 {
  background-color: #ffcc66;
}
.bg-CC9900 {
  background-color: #cc9900;
}
.bg-FFCC33 {
  background-color: #ffcc33;
}
.bg-FFCC00 {
  background-color: #ffcc00;
}
.bg-999966 {
  background-color: #999966;
}
.bg-666633 {
  background-color: #666633;
}
.bg-CCCC99 {
  background-color: #cccc99;
}
.bg-999933 {
  background-color: #999933;
}
.bg-CCCC66 {
  background-color: #cccc66;
}
.bg-CCCC33 {
  background-color: #cccc33;
}
.bg-333300 {
  background-color: #333300;
}
.bg-666600 {
  background-color: #666600;
}
.bg-999900 {
  background-color: #999900;
}
.bg-CCCC00 {
  background-color: #cccc00;
}
.bg-FFFF00 {
  background-color: #ffff00;
}
.bg-FFFF33 {
  background-color: #ffff33;
}
.bg-FFFF66 {
  background-color: #ffff66;
}
.bg-FFFF99 {
  background-color: #ffff99;
}
.bg-FFFFCC {
  background-color: #ffffcc;
}
.bg-CCFF00 {
  background-color: #ccff00;
}
.bg-99CC00 {
  background-color: #99cc00;
}
.bg-CCFF33 {
  background-color: #ccff33;
}
.bg-99CC33 {
  background-color: #99cc33;
}
.bg-669900 {
  background-color: #669900;
}
.bg-CCFF66 {
  background-color: #ccff66;
}
.bg-99FF00 {
  background-color: #99ff00;
}
.bg-669933 {
  background-color: #669933;
}
.bg-99CC66 {
  background-color: #99cc66;
}
.bg-336600 {
  background-color: #336600;
}
.bg-66CC00 {
  background-color: #66cc00;
}
.bg-99FF33 {
  background-color: #99ff33;
}
.bg-CCFF99 {
  background-color: #ccff99;
}
.bg-66FF00 {
  background-color: #66ff00;
}
.bg-66CC33 {
  background-color: #66cc33;
}
.bg-339900 {
  background-color: #339900;
}
.bg-99FF66 {
  background-color: #99ff66;
}
.bg-33CC00 {
  background-color: #33cc00;
}
.bg-66FF33 {
  background-color: #66ff33;
}
.bg-33FF00 {
  background-color: #33ff00;
}
.bg-669966 {
  background-color: #669966;
}
.bg-336633 {
  background-color: #336633;
}
.bg-99CC99 {
  background-color: #99cc99;
}
.bg-339933 {
  background-color: #339933;
}
.bg-66CC66 {
  background-color: #66cc66;
}
.bg-33CC33 {
  background-color: #33cc33;
}
.bg-003300 {
  background-color: #003300;
}
.bg-006600 {
  background-color: #006600;
}
.bg-009900 {
  background-color: #009900;
}
.bg-00CC00 {
  background-color: #00cc00;
}
.bg-00FF00 {
  background-color: #00ff00;
}
.bg-33FF33 {
  background-color: #33ff33;
}
.bg-66FF66 {
  background-color: #66ff66;
}
.bg-99FF99 {
  background-color: #99ff99;
}
.bg-CCFFCC {
  background-color: #ccffcc;
}
.bg-00FF33 {
  background-color: #00ff33;
}
.bg-00CC33 {
  background-color: #00cc33;
}
.bg-33FF66 {
  background-color: #33ff66;
}
.bg-33CC66 {
  background-color: #33cc66;
}
.bg-009933 {
  background-color: #009933;
}
.bg-66FF99 {
  background-color: #66ff99;
}
.bg-00FF66 {
  background-color: #00ff66;
}
.bg-339966 {
  background-color: #339966;
}
.bg-66CC99 {
  background-color: #66cc99;
}
.bg-006633 {
  background-color: #006633;
}
.bg-00CC66 {
  background-color: #00cc66;
}
.bg-33FF99 {
  background-color: #33ff99;
}
.bg-99FFCC {
  background-color: #99ffcc;
}
.bg-00FF99 {
  background-color: #00ff99;
}
.bg-33CC99 {
  background-color: #33cc99;
}
.bg-009966 {
  background-color: #009966;
}
.bg-66FFCC {
  background-color: #66ffcc;
}
.bg-00CC99 {
  background-color: #00cc99;
}
.bg-33FFCC {
  background-color: #33ffcc;
}
.bg-00FFCC {
  background-color: #00ffcc;
}
.bg-669999 {
  background-color: #669999;
}
.bg-336666 {
  background-color: #336666;
}
.bg-99CCCC {
  background-color: #99cccc;
}
.bg-339999 {
  background-color: #339999;
}
.bg-66CCCC {
  background-color: #66cccc;
}
.bg-33CCCC {
  background-color: #33cccc;
}
.bg-003333 {
  background-color: #003333;
}
.bg-006666 {
  background-color: #006666;
}
.bg-009999 {
  background-color: #009999;
}
.bg-00CCCC {
  background-color: #00cccc;
}
.bg-00FFFF {
  background-color: #00ffff;
}
.bg-33FFFF {
  background-color: #33ffff;
}
.bg-66FFFF {
  background-color: #66ffff;
}
.bg-99FFFF {
  background-color: #99ffff;
}
.bg-CCFFFF {
  background-color: #ccffff;
}
.bg-00CCFF {
  background-color: #00ccff;
}
.bg-0099CC {
  background-color: #0099cc;
}
.bg-33CCFF {
  background-color: #33ccff;
}
.bg-3399CC {
  background-color: #3399cc;
}
.bg-006699 {
  background-color: #006699;
}
.bg-66CCFF {
  background-color: #66ccff;
}
.bg-0099FF {
  background-color: #0099ff;
}
.bg-336699 {
  background-color: #336699;
}
.bg-6699CC {
  background-color: #6699cc;
}
.bg-003366 {
  background-color: #003366;
}
.bg-0066CC {
  background-color: #0066cc;
}
.bg-3399FF {
  background-color: #3399ff;
}
.bg-99CCFF {
  background-color: #99ccff;
}
.bg-0066FF {
  background-color: #0066ff;
}
.bg-3366CC {
  background-color: #3366cc;
}
.bg-003399 {
  background-color: #003399;
}
.bg-6699FF {
  background-color: #6699ff;
}
.bg-0033CC {
  background-color: #0033cc;
}
.bg-3366FF {
  background-color: #3366ff;
}
.bg-0033FF {
  background-color: #0033ff;
}
.bg-666699 {
  background-color: #666699;
}
.bg-333366 {
  background-color: #333366;
}
.bg-9999CC {
  background-color: #9999cc;
}
.bg-333399 {
  background-color: #333399;
}
.bg-6666CC {
  background-color: #6666cc;
}
.bg-3333CC {
  background-color: #3333cc;
}
.bg-000033 {
  background-color: #000033;
}
.bg-000066 {
  background-color: #000066;
}
.bg-000099 {
  background-color: #000099;
}
.bg-0000CC {
  background-color: #0000cc;
}
.bg-0000FF {
  background-color: #0000ff;
}
.bg-3333FF {
  background-color: #3333ff;
}
.bg-6666FF {
  background-color: #6666ff;
}
.bg-9999FF {
  background-color: #9999ff;
}
.bg-CCCCFF {
  background-color: #ccccff;
}
.bg-3300FF {
  background-color: #3300ff;
}
.bg-3300CC {
  background-color: #3300cc;
}
.bg-6633FF {
  background-color: #6633ff;
}
.bg-6633CC {
  background-color: #6633cc;
}
.bg-330099 {
  background-color: #330099;
}
.bg-9966FF {
  background-color: #9966ff;
}
.bg-6600FF {
  background-color: #6600ff;
}
.bg-663399 {
  background-color: #663399;
}
.bg-9966CC {
  background-color: #9966cc;
}
.bg-330066 {
  background-color: #330066;
}
.bg-6600CC {
  background-color: #6600cc;
}
.bg-9933FF {
  background-color: #9933ff;
}
.bg-CC99FF {
  background-color: #cc99ff;
}
.bg-9900FF {
  background-color: #9900ff;
}
.bg-9933CC {
  background-color: #9933cc;
}
.bg-660099 {
  background-color: #660099;
}
.bg-CC66FF {
  background-color: #cc66ff;
}
.bg-9900CC {
  background-color: #9900cc;
}
.bg-CC33FF {
  background-color: #cc33ff;
}
.bg-CC00FF {
  background-color: #cc00ff;
}
.bg-996699 {
  background-color: #996699;
}
.bg-663366 {
  background-color: #663366;
}
.bg-CC99CC {
  background-color: #cc99cc;
}
.bg-993399 {
  background-color: #993399;
}
.bg-CC66CC {
  background-color: #cc66cc;
}
.bg-CC33CC {
  background-color: #cc33cc;
}
.bg-330033 {
  background-color: #330033;
}
.bg-660066 {
  background-color: #660066;
}
.bg-990099 {
  background-color: #990099;
}
.bg-CC00CC {
  background-color: #cc00cc;
}
.bg-FF00FF {
  background-color: #ff00ff;
}
.bg-FF33FF {
  background-color: #ff33ff;
}
.bg-FF66FF {
  background-color: #ff66ff;
}
.bg-FF99FF {
  background-color: #ff99ff;
}
.bg-FFCCFF {
  background-color: #ffccff;
}
.bg-FF00CC {
  background-color: #ff00cc;
}
.bg-CC0099 {
  background-color: #cc0099;
}
.bg-FF33CC {
  background-color: #ff33cc;
}
.bg-CC3399 {
  background-color: #cc3399;
}
.bg-990066 {
  background-color: #990066;
}
.bg-FF66CC {
  background-color: #ff66cc;
}
.bg-FF0099 {
  background-color: #ff0099;
}
.bg-993366 {
  background-color: #993366;
}
.bg-CC6699 {
  background-color: #cc6699;
}
.bg-660033 {
  background-color: #660033;
}
.bg-CC0066 {
  background-color: #cc0066;
}
.bg-FF3399 {
  background-color: #ff3399;
}
.bg-FF99CC {
  background-color: #ff99cc;
}
.bg-FF0066 {
  background-color: #ff0066;
}
.bg-CC3366 {
  background-color: #cc3366;
}
.bg-990033 {
  background-color: #990033;
}
.bg-FF6699 {
  background-color: #ff6699;
}
.bg-CC0033 {
  background-color: #cc0033;
}
.bg-FF3366 {
  background-color: #ff3366;
}
.bg-FF0033 {
  background-color: #ff0033;
}
.bg-015b97 {
  background-color: #015b9749;
}
.bg-fe3fff {
  background-color: #ff3fff63;
}
.bg-FFFFFF-low {
  background: rgba(255, 255, 255, 0.5);
}
.bg-gr-015B97-FE3FFF {
  background: linear-gradient(90deg, #015b97, #fe3fff);
}
.bg-gr-015B97-00f4f4 {
  background: linear-gradient(90deg, #015b97, #00f4f4);
}
.main-bg {
  background-image: radial-gradient(circle at 46% 40%, rgba(228, 228, 228, 0.06) 0%, rgba(228, 228, 228, 0.06) 13%, transparent 13%, transparent 100%),
    radial-gradient(circle at 11% 41%, rgba(198, 198, 198, 0.06) 0%, rgba(198, 198, 198, 0.06) 19%, transparent 19%, transparent 100%),
    radial-gradient(circle at 52% 23%, rgba(14, 14, 14, 0.06) 0%, rgba(14, 14, 14, 0.06) 69%, transparent 69%, transparent 100%),
    radial-gradient(circle at 13% 85%, rgba(148, 148, 148, 0.06) 0%, rgba(148, 148, 148, 0.06) 44%, transparent 44%, transparent 100%),
    radial-gradient(circle at 57% 74%, rgba(232, 232, 232, 0.06) 0%, rgba(232, 232, 232, 0.06) 21%, transparent 21%, transparent 100%),
    radial-gradient(circle at 59% 54%, rgba(39, 39, 39, 0.06) 0%, rgba(39, 39, 39, 0.06) 49%, transparent 49%, transparent 100%),
    radial-gradient(circle at 98% 38%, rgba(157, 157, 157, 0.06) 0%, rgba(157, 157, 157, 0.06) 24%, transparent 24%, transparent 100%),
    radial-gradient(circle at 8% 6%, rgba(60, 60, 60, 0.06) 0%, rgba(60, 60, 60, 0.06) 12%, transparent 12%, transparent 100%),
    linear-gradient(260deg, rgb(254, 63, 255), rgb(1, 91, 151));
}
/*FONT FACE */
.ff-montserrat {
  font-family: "Montserrat";
}
.ff-sans-serif {
  font-family: "sans-serif";
}
.ff-open-sans {
  font-family: "Open Sans";
}
/*FONT SIZE*/
.fs-8 {
  font-size: 8px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-26 {
  font-size: 26px;
}
.fs-28 {
  font-size: 28px;
}
.fs-30 {
  font-size: 30px;
}
.fs-32 {
  font-size: 32px;
}
.fs-34 {
  font-size: 34px;
}
.fs-36 {
  font-size: 36px;
}
.fs-38 {
  font-size: 38px;
}
.fs-40 {
  font-size: 40px;
}
.fs-42 {
  font-size: 42px;
}
.fs-44 {
  font-size: 44px;
}
.fs-46 {
  font-size: 46px;
}
.fs-48 {
  font-size: 48px;
}
.fs-50 {
  font-size: 50px;
}
.fs-52 {
  font-size: 52px;
}
.fs-54 {
  font-size: 54px;
}
.fs-56 {
  font-size: 56px;
}
.fs-58 {
  font-size: 58px;
}
.fs-60 {
  font-size: 60px;
}
.fs-62 {
  font-size: 62px;
}
.fs-64 {
  font-size: 64px;
}
.fs-66 {
  font-size: 66px;
}
.fs-68 {
  font-size: 68px;
}
.fs-70 {
  font-size: 70px;
}
.fs-72 {
  font-size: 72px;
}
.fs-74 {
  font-size: 74px;
}
.fs-76 {
  font-size: 76px;
}
.fs-78 {
  font-size: 78px;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-900 {
  font-weight: 900;
}
.fw-700 {
  font-weight: 700;
}
.fw-bolder {
  font-weight: bold;
}
/*CURSOR*/
.cr-pointer {
  cursor: pointer;
}
.cr-not-allow {
  cursor: not-allowed;
}
/*TEXT ALIGN*/
.text-shadow-545354 {
  text-shadow: 0px 1px 5px #545354;
}
.txt-align-end {
  text-align: end;
}
.txt-align-start {
  text-align: start;
}
.txt-align-center {
  text-align: center;
}
.txt-align-justify {
  text-align: justify;
}

/*BORDER*/
.br-left-015b97 {
  border-left: solid 5px #015b97;
}
.br-right-fe3fff {
  border-right: solid 5px #fe3fff;
}
.br-left-ffffff {
  border-right: solid 5px #fff;
}
.br-bottom-2-fff {
  border-bottom: solid 2px #fff;
}
.br-2-fff {
  border: solid 5px #fff;
}

/*BORDER RADIOUS*/
.br-5p {
  border-radius: 5px;
}
.br-25p {
  border-radius: 25px;
}
.br-10p {
  border-radius: 10px;
}
.opacity-01 {
  opacity: 0.1;
}
.opacity-04 {
  opacity: 0.4;
}
.opacity-05 {
  opacity: 0.5;
}

/**/
.box-shadow-5-f7408c {
  box-shadow: 0px 0px 5px #f7408c;
}

/*COMMON TAG CSS*/
.input_box {
  outline: none;
  border: 0px;
  background-color: rgba(255, 255, 255, 0.5);
  text-indent: 10px;
  font-size: 18px;
}
.triangle {
  position: relative;
  background: #99067944;
  height: 50vh;
}

.triangle::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 53px 53px 0 53px;
  border-color: #99067944 transparent transparent transparent;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
}

/* .amazone-logo {
  width: 80%;
  height: 80%;
  margin: 10%;
  background-position: center;
  background-size: contain;
  background: url("../images/amazone.png") no-repeat;
}
.jobong-logo {
  width: 80%;
  height: 80%;
  margin: 10%;
  background-position: center;
  background-size: contain;
  background: url("../images/jobong.png") no-repeat;
}
.myntra-logo {
  width: 80%;
  height: 80%;
  margin: 10%;
  background-position: center;
  background-size: contain;
  background: url("../images/myntra.png") no-repeat;
}
.paytm-logo {
  width: 80%;
  height: 80%;
  margin: 10%;
  background-position: center;
  background-size: contain;
  background: url("../images/paytm.png") no-repeat;
}
.shopcluse-logo {
  width: 80%;
  height: 80%;
  margin: 10%;
  background-position: center;
  background-size: contain;
  background: url("../images/shopcluse.png") no-repeat;
}
.snapdeal-logo {
  width: 80%;
  height: 80%;
  margin: 10%;
  background-position: center;
  background-size: contain;
  background: url("../images/snapdeal.png") no-repeat;
} */

@media only screen and (max-width: 766px) {
  .display-block-tab {
    display: block !important;
  }
  .carousel-caption h3 {
    width: auto !important;
    padding-left: 0em !important;
    font-size: 4em;
    font-weight: 500;
  }
  .carousel-caption {
    top: 20% !important;
  }
  .navbar {
    background-color: #0e153a !important;
  }
  .ba-details {
    width: 90%;
    text-align: justify;
    font-size: 14px;
  }
  .ba-title {
    font-weight: 600;
    font-size: 22px;
  }
  .ba-an-img {
    width: 60%;
  }
  .ba-detail-left {
    width: 90%;
  }
  .ba-detail-right {
    width: 90%;
  }
  .ci-details-width {
    width: 100%;
  }
  .ba-details-width {
    width: 100%;
  }
  .sv-details-width {
    width: 100%;
    flex-wrap: wrap;
  }
  .sv-details-title {
    width: 100%;
  }
  .sv-details-img {
    width: 100%;
  }
  .colored-7v {
    padding-top: 5em !important;
  }
  .card {
    width: 210 !important;
    margin: 2rem 1rem !important;
    height: 210px !important;
  }
  #contact-us.button,
  a.button,
  span.button,
  input[type="button"],
  input[type="submit"] {
    padding: 11px 18px;
    margin-bottom: 8px;
  }
  #contact-us h1 {
    font-size: 14px;
  }
  .fp-main-details {
    margin-left: 0px !important;
  }
  .fp-main-details > div > div:nth-child(1),
  .fp-main-details > div > div:nth-child(2) {
    padding-left: 20px !important;
  }
  .demo-input {
    padding-top: 2px !important;
    width: 70% !important;
  }
  label {
    padding-left: 20px !important;
    width: 10% !important;
  }
  .demo-h {
    font-size: 1.5rem !important;
  }
  #contact-us h1 {
    font-size: 14px;
  }
  #TPmc0 , #TPmc1, #TPmc2 {
    margin: auto !important;
  }
}

@media only screen and (max-width: 480px){
  .ba-an-img {
    width: 80%;
  }
  .ba-detail-left {
    width: 90%;
  }
  .ba-detail-right {
    width: 90%;
  }
  .display-block-mob {
    display: block !important;
  }
  .ba-details-icon {
    width: 35px;
    height: 35px;
  }
  .ba-details-title {
    font-size: 18px;
  }
  .ba-details-width {
    width: 100%;
    flex-wrap: wrap;
  }
  .sv-details-title {
    width: 100%;
  }
  .sv-details-img {
    width: 100%;
  }
  .colored-7v {
    padding-top: 5em !important;
  }
  .card {
    width: 40% !important;
    margin: 1rem 0.5rem !important;
    height: 210px !important;
  }
  .sv-main-box-title {
    font-weight: 900;
    font-size: 18px;
  }
  .sv-box-bg {
    background-size: 51% !important;
  }
  .why-us-svg {
    margin-top: 1.5em !important;
    height: 35px;
  }
  .flip-card-front > p {
    font-size: 20px;
  }
  .flip-card-back > p {
    font-size: 12px;
  }
  .why-us img {
    height: 200px !important;
    width: 200px !important;
  }
  .colored-7v {
    padding-left: 2em !important;
  }
  .fp-main-details > div > div:nth-child(1),
  .fp-main-details > div > div:nth-child(2) {
    padding-left: 0px !important;
  }
  .fp-main-details > div {
    padding: 10px !important;
  }
  #fp-detail-list {
    width: 100% !important;
  }
  .overlay-number {
    font-size: 4em !important;
  }
  .each-fade {
    margin-top: 10px !important;
  }
  .fps-details-width {
    width: 100% !important;
  }

  .demo-input {
    width: 72.5% !important;
  }
  label {
    padding-left: 20px !important;
    width: 10% !important;
  }
  .mc-an-img {
    width: 100%;
  }
  #map-cons {
    margin: auto !important;
  }
  .mc-box-title {
    text-align: start;
    color: #000;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
  .carousel-caption h3 {
    margin-top: 25%!important;
  }
}
@media (max-width: 360px) {
  .flip-card-front > p {
    font-size: 16px;
  }
  .flip-card-back > p {
    font-size: 12px;
  }
  .why-us img {
    height: 175px !important;
    width: 175px !important;
  }
  .demo-input {
    width: 72.5%;
  }
}
@media only screen and (max-width: 1024px) {
  .ci-details-width {
    width: 100%;
  }
  .ba-details-width {
    width: 100%;
  }
  /* .sv-details-width {
    width: 100%;
    flex-wrap: wrap;
  } */
  .comp-l-r-padding {
    padding-left: 25px;
    padding-right: 25px;
  }
  .colored-7v {
    padding-top: 5em !important;
  }
}

