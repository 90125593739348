@media only screen and (max-width: 766px) {
  .display-block-tab {
    display: block !important;
  }
  .carousel-caption h3 {
    width: auto !important;
    padding-left: 0em !important;
    font-size: 4em;
    font-weight: 500;
  }
  .carousel-caption {
    top: 20% !important;
  }
  .navbar {
    background-color: #0e153a !important;
  }
  .ba-details {
    width: 90%;
    text-align: justify;
    font-size: 14px;
  }
  .ba-title {
    font-weight: 600;
    font-size: 22px;
  }
  .ba-an-img {
    width: 60%;
  }
  .ba-detail-left {
    width: 90%;
  }
  .ba-detail-right {
    width: 90%;
  }
  .ci-details-width {
    width: 100%;
  }
  .ba-details-width {
    width: 100%;
  }
  .sv-details-width {
    width: 100%;
    flex-wrap: wrap;
  }
  .sv-details-title {
    width: 100%;
  }
  .sv-details-img {
    width: 100%;
  }
  .colored-7v {
    padding-top: 5em !important;
  }
  .card {
    width: 210 !important;
    margin: 2rem 1rem !important;
    height: 210px !important;
  }
  #contact-us.button,
  a.button,
  span.button,
  input[type="button"],
  input[type="submit"] {
    padding: 11px 18px;
    margin-bottom: 8px;
  }
  #contact-us h1 {
    font-size: 14px;
  }
  .fp-main-details {
    margin-left: 0px !important;
  }
  .fp-main-details > div > div:nth-child(1),
  .fp-main-details > div > div:nth-child(2) {
    padding-left: 20px !important;
  }
  .demo-input {
    padding-top: 2px !important;
    width: 70% !important;
  }
  label {
    padding-left: 20px !important;
    width: 10% !important;
  }
  .demo-h {
    font-size: 1.5rem !important;
  }
  #contact-us h1 {
    font-size: 14px;
  }
  #TPmc0 , #TPmc1, #TPmc2 {
    margin: auto !important;
  }
}

@media only screen and (max-width: 480px){
  .ba-an-img {
    width: 80%;
  }
  .ba-detail-left {
    width: 90%;
  }
  .ba-detail-right {
    width: 90%;
  }
  .display-block-mob {
    display: block !important;
  }
  .ba-details-icon {
    width: 35px;
    height: 35px;
  }
  .ba-details-title {
    font-size: 18px;
  }
  .ba-details-width {
    width: 100%;
    flex-wrap: wrap;
  }
  .sv-details-title {
    width: 100%;
  }
  .sv-details-img {
    width: 100%;
  }
  .colored-7v {
    padding-top: 5em !important;
  }
  .card {
    width: 40% !important;
    margin: 1rem 0.5rem !important;
    height: 210px !important;
  }
  .sv-main-box-title {
    font-weight: 900;
    font-size: 18px;
  }
  .sv-box-bg {
    background-size: 51% !important;
  }
  .why-us-svg {
    margin-top: 1.5em !important;
    height: 35px;
  }
  .flip-card-front > p {
    font-size: 20px;
  }
  .flip-card-back > p {
    font-size: 12px;
  }
  .why-us img {
    height: 200px !important;
    width: 200px !important;
  }
  .colored-7v {
    padding-left: 2em !important;
  }
  .fp-main-details > div > div:nth-child(1),
  .fp-main-details > div > div:nth-child(2) {
    padding-left: 0px !important;
  }
  .fp-main-details > div {
    padding: 10px !important;
  }
  #fp-detail-list {
    width: 100% !important;
  }
  .overlay-number {
    font-size: 4em !important;
  }
  .each-fade {
    margin-top: 10px !important;
  }
  .fps-details-width {
    width: 100% !important;
  }

  .demo-input {
    width: 72.5% !important;
  }
  label {
    padding-left: 20px !important;
    width: 10% !important;
  }
  .mc-an-img {
    width: 100%;
  }
  #map-cons {
    margin: auto !important;
  }
  .mc-box-title {
    text-align: start;
    color: #000;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
  .carousel-caption h3 {
    margin-top: 25%!important;
  }
}
@media (max-width: 360px) {
  .flip-card-front > p {
    font-size: 16px;
  }
  .flip-card-back > p {
    font-size: 12px;
  }
  .why-us img {
    height: 175px !important;
    width: 175px !important;
  }
  .demo-input {
    width: 72.5%;
  }
}
@media only screen and (max-width: 1024px) {
  .ci-details-width {
    width: 100%;
  }
  .ba-details-width {
    width: 100%;
  }
  /* .sv-details-width {
    width: 100%;
    flex-wrap: wrap;
  } */
  .comp-l-r-padding {
    padding-left: 25px;
    padding-right: 25px;
  }
  .colored-7v {
    padding-top: 5em !important;
  }
}
